import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { Link, IndexLink } from 'react-router';
// icons
import IconDashboard from 'react-icons/lib/md/dashboard';
import IconMail from 'react-icons/lib/md/mail';
import IconHome from 'react-icons/lib/md/home';
import IconAdd from 'react-icons/lib/md/add';

import { Components } from 'musalleen-react-theme';

import './style.scss';

const { Logo } = Components;

class NavList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null
        };
    }

    handleClick = (index, e) => {
        const c = e.currentTarget.className;
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            selected: c.indexOf('selected') >= 0 ? '' : index
        });
        // Push to top most screen when dropdown is clicked.
        // const {
        //     selected
        // } = this.state;
        // if (selected !== index && pathname) {
        //     browserHistory.push(pathname);
        // }
    };

    handleOpen = (index, e) => {
        e.stopPropagation();
        this.setState({
            selected: index
        });
    };

    render() {
        const { selected } = this.state;
        return (
            <div className="nav-list-container">
                <ul className="list-unstyled nav-list clearfix mt-2">
                    {/* <li><div className="nav-list-title">Dashboard</div></li> */}
                    <li // eslint-disable-line
                        onClick={this.handleClick.bind(this, 0)}
                        className={selected === 0 ? 'selected' : ''}
                    >
                        <IndexLink to="/" activeClassName="active">
                            <IconDashboard size="18" color="#2962FF" className="icon-dashboard" />
                            <span className="name">Dashboard</span>
                        </IndexLink>
                    </li>
                    <li // eslint-disable-line
                        onClick={this.handleClick.bind(this, 1)}
                        className={selected === 1 ? 'selected' : ''}
                    >
                        <Link to="/masjids/list" activeClassName="active">
                            <IconHome size="18" color="#2962FF" className="icon-dashboard" />
                            <span className="name">Masjids</span>
                        </Link>
                    </li>
                    <li // eslint-disable-line
                        onClick={this.handleClick.bind(this, 3)}
                        className={selected === 3 ? 'selected' : ''}
                    >
                        <Link to="/masjids/add" activeClassName="active">
                            <IconAdd size="18" color="#2962FF" className="icon-dashboard" />
                            <span className="name">Add New Masjid</span>
                        </Link>
                    </li>
                    <li // eslint-disable-line
                        onClick={this.handleClick.bind(this, 2)}
                        className={selected === 2 ? 'selected' : ''}
                    >
                        <Link to="/email" activeClassName="active">
                            <IconMail size="18" color="#2962FF" className="icon-dashboard" />
                            <span className="name">Email Admins</span>
                        </Link>
                    </li>
                </ul>
                {/* end scroll-area */}
            </div>
        );
    }
}

const StateAwareNavList = NavList;

/* eslint-disable */
class Drawer extends React.Component {
    /* eslint-enable */
    constructor(props) {
        super(props);
        this.drawer = createRef();
    }

    componentDidMount = () => {
        // This will disallow scrolling of content
        // that's beneath or beside the sidebar when the sidebar is scrolled.
        this.drawer.current.ontouchmove = this.preventDefault;
        this.drawer.current.onwheel = this.onMouseWheel;
    };

    // ~ Reference - https://stackoverflow.com/a/9786325/7641174.
    /* eslint-disable no-param-reassign */
    onMouseWheel = event => {
        event.target.scrollTop -= event.wheelDeltaY;
        this.preventDefault(event);
    };

    preventDefault = event => {
        event = event || window.event;
        if (event.preventDefault) {
            event.preventDefault();
        }
        event.returnValue = false;
    };
    /* eslint-enable */

    render() {
        const { drawerHidden } = this.props;
        return (
            <nav ref={this.drawer} className={`site-nav ${drawerHidden ? 'mini' : ''}`}>
                <header className="nav-head">
                    <Link to="/">
                        <Logo />
                    </Link>
                </header>
                <StateAwareNavList />
            </nav>
        );
    }
}

Drawer.propTypes = {
    drawerHidden: PropTypes.bool.isRequired
};

export default Drawer;
