import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Components } from 'musalleen-react-theme';
import { isLoggedIn } from './store/selectors';
import { refreshMasjids } from './store/actions';
import 'musalleen-react-theme/lib/theme/theme.scss';
import { Header, Drawer } from './shared/components';
import './App.scss';

const { Loader } = Components;

function App({ children, isAdminLoggedIn, refreshingMasjid, dispatch }) {
    const [drawerHidden, setDrawerHidden] = useState(false);

    useEffect(() => {
        // Refresh data of masjids in case we need to.
        const unlisten = refresh();
        return () => unlisten && unlisten();
    }, []);

    function refresh() {
        if (!isAdminLoggedIn) {
            // If admin is not logged in, show a sign in screen instead and
            // don't allow access inside App.
            browserHistory.replace('/signin');
            return null;
        }
        // Refresh data of Masjids in case we need to.
        dispatch(refreshMasjids());
        // Do it on every page change except for Signin and 404.
        // return browserHistory.listen(({ pathname }) => {
        //     if (!(pathname.includes('signin') || pathname.includes('404'))) {
        //         refresh();
        //     }
        // });
    }

    function toggleDrawer() {
        setDrawerHidden(!drawerHidden);
    }

    function hideDrawer() {
        setDrawerHidden(false);
    }

    if (!isAdminLoggedIn) {
        return null;
    }

    if (refreshingMasjid) {
        return (
            <div className="app-wrapper">
                <div className="loader-container">
                    <Loader height={200} thin />
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            <Drawer drawerHidden={drawerHidden} />
            <div className={`content-container ${drawerHidden ? 'full' : ''}`}>
                {/* dropshadow for mobile nav triggering */}
                <div
                    role="button"
                    className="menu-dropshadow"
                    style={{ display: drawerHidden ? 'block' : 'none' }}
                    onClick={hideDrawer}
                />
                <Header toggleDrawer={toggleDrawer} />
                {children}
            </div>
        </Fragment>
    );
}

App.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
        .isRequired,
    isAdminLoggedIn: PropTypes.bool.isRequired,
    refreshingMasjid: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isAdminLoggedIn: isLoggedIn(state),
    // Display a loading state only when there is no stale data
    // and the data of masjids is refreshing. (i.e It is fresh page load)
    refreshingMasjid: state.masjids.refreshing && !state.masjids.updatedAt
});

export default connect(
    mapStateToProps,
    null,
    null,
    {
        areStatesEqual: (nextState, prevState) =>
            nextState.admin === prevState.admin &&
            nextState.masjids.refreshing === prevState.masjids.refreshing &&
            nextState.masjids.updatedAt === prevState.masjids.updatedAt
    }
)(App);
