import { produce } from 'immer';
import { SET_ADMIN_TOKEN, SET_ADMIN_DETAILS, REMOVE_ADMIN } from '../types';

const INITIAL_STATE = {
    firstName: '',
    lastName: '',
    email: '',
    token: '',
    expires: Infinity
};

/* eslint-disable no-param-reassign */
const admin = produce((draft, { type, payload }) => {
    switch (type) {
        case SET_ADMIN_TOKEN: {
            const { token, expires } = payload;
            draft.token = token;
            draft.expires = expires;
            break;
        }
        case SET_ADMIN_DETAILS: {
            const { firstName, lastName, email } = payload;
            draft.firstName = firstName;
            draft.lastName = lastName;
            draft.email = email;
            break;
        }
        case REMOVE_ADMIN:
            return { ...INITIAL_STATE };
        default:
    }
    return draft;
}, INITIAL_STATE);
/* eslint-enable */

export default admin;
