import { create, SERVER_ERROR, CLIENT_ERROR, CONNECTION_ERROR, NETWORK_ERROR } from 'apisauce';
import { store } from '../store';

// TODO Change this API url.
const url = process.env.REACT_APP_API_URL || 'https://musalleen-develop.herokuapp.com';
// const url = 'http://localhost:5000';

const stripeDashboardUrl = 'https://dashboard.stripe.com/customers';

const client = create({ baseURL: `${url}/api` });

const Constants = {
    SERVER_ERROR,
    CLIENT_ERROR,
    CONNECTION_ERROR,
    NETWORK_ERROR
};

client.addRequestTransform(request => {
    const {
        admin: { token, expires }
    } = store.getState();

    // If the admin is logged in, add the basic auth header.
    if (token && expires >= new Date().getTime()) {
        request.headers.authorization = `Basic ${token}`;
    }
});

const returnResponse = ({ ok, problem, data, status }) => ({
    error: !ok ? problem : null,
    data,
    status
});

export { Constants, returnResponse, stripeDashboardUrl };

export default client;
