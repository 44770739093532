import { produce } from 'immer';
import { SET_MASJIDS, REMOVE_MASJIDS, REFRESHING_MASJIDS } from '../types';
import { EnvironmentUtils } from '../../utils';

// Time after which masjid data will go stale and
// a new API call will be made for each refreshMasjids action.
// Otherwise no API call is made. (seconds)
const REFRESH_INTERVAL = EnvironmentUtils.isRunningInDevelopmentMode() ? 10 : 30;

const INITIAL_STATE = {
    masjids: [],
    updatedAt: 0,
    interval: REFRESH_INTERVAL * 1000,
    refreshing: true
};

/* eslint-disable no-param-reassign */
const masjids = produce((draft, { type, payload }) => {
    switch (type) {
        case SET_MASJIDS:
            draft.masjids = payload.masjids;
            draft.updatedAt = new Date().getTime();
            break;
        case REFRESHING_MASJIDS:
            draft.refreshing = payload.refreshing;
            break;
        case REMOVE_MASJIDS:
            return { ...INITIAL_STATE };
        default:
    }
    return draft;
}, INITIAL_STATE);
/* eslint-enable */

export default masjids;
