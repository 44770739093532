import { store } from 'musalleen-react-theme';
import createActionCreator from './create';
import { SET_ADMIN_TOKEN, SET_ADMIN_DETAILS, REMOVE_ADMIN, SET_MASJIDS } from '../types';
import { AdminAPI, Constants } from '../../api';
import { removeBugsnagUser, setBugsnagUser } from '../../provider/ErrorBoundary';
import { setAnalytics } from './analytics';

const base64 = require('base-64');

const { showNoInternetAlert, showServerErrorAlert } = store.actions;

export const setAdminToken = createActionCreator(SET_ADMIN_TOKEN, 'token', 'expires');
export const setAdminDetails = createActionCreator(
    SET_ADMIN_DETAILS,
    'firstName',
    'lastName',
    'email'
);
export const removeAdmin = createActionCreator(REMOVE_ADMIN);
export const setMasjids = createActionCreator(SET_MASJIDS, 'masjids');

// This action creator will return an API error if there's any.
// The UI only needs to handle a wrong combination of username and password.
// Other errors are handled automatically inside this action creator.
export const login = (email, password) => async dispatch => {
    const { error, data } = await AdminAPI.login(email, password);
    if (error) {
        if (error === Constants.SERVER_ERROR) {
            dispatch(showServerErrorAlert());
        }
        if (error === Constants.NETWORK_ERROR || error === Constants.CONNECTION_ERROR) {
            dispatch(showNoInternetAlert());
        }
        // UI needs to handle CLIENT_ERROR.
        return error;
    }

    // Let's now save the admin token.
    dispatch(
        setAdminToken(base64.encode(`${data.token}:x`), new Date().getTime() + data.duration * 1000)
    );

    // Since we have the token we can make this request
    // and query the Ops Admins's data.
    const adminResponse = await AdminAPI.get(email);
    if (adminResponse.error) {
        // Ignoring CLIENT_ERROR since we won't get that here.
        if (adminResponse.error === Constants.SERVER_ERROR) {
            dispatch(showServerErrorAlert());
            return adminResponse.error;
        }
        // In case of NETWORK_ERROR or CONNECTION_ERROR.
        dispatch(showNoInternetAlert());
        return adminResponse.error;
    }

    const {
        data: {
            admin: { FirstName, LastName, Email },
            masjids,
            analytics
        }
    } = adminResponse;

    // Save the rest of admin's details.
    dispatch(setAdminDetails(FirstName, LastName, Email));

    // Save the masjids.
    dispatch(setMasjids(masjids));

    // Save the analytics.
    dispatch(setAnalytics(analytics));

    // Set the user inside Bugsnag.
    setBugsnagUser(`${FirstName} ${LastName}`, Email, 0, 'Ops');

    return null;
};

// This will log the user out and remove admin inside our store.
export const logout = () => {
    // Removing the user details from Bugsnag too.
    removeBugsnagUser();

    return removeAdmin();
};
