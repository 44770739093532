import { store } from 'musalleen-react-theme';
import analytics from './analytics';
import masjids from './masjids';
import admin from './admin';

const reducers = store.combineReducers({
    analytics,
    masjids,
    admin
});

export default reducers;
