import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Components, hooks, store } from 'musalleen-react-theme';
import { Row } from 'reactstrap';
import { PageHeader, EmailModal } from '../../shared/components';
import { getMasjids } from '../../store/selectors';
import { AdminUtils } from '../../utils';
import { AdminAPI, Constants } from '../../api';

const { InfoCard, Button } = Components;
const { useBoolean } = hooks;
const { showServerErrorAlert, showNoInternetAlert, showSuccessAlert } = store.actions;

function EmailAdmins({ masjids, dispatch }) {
    const [allEmailsChecked, setAllEmailsChecked] = useState(false);
    const [allSuperEmailsChecked, setAllSuperEmailsChecked] = useState(false);
    const [masjidEmailsChecked, toggleMasjidEmailChecked] = useState({});
    const [emailsChecked, toggleEmailChecked] = useState({});
    const [emailModalVisible, showEmailModal, hideEmailModal] = useBoolean(false);
    const [isSendingEmail, currentlySendingEmail, currentlyNotSendingEmail] = useBoolean(false);

    async function sendEmailToAdmin(emailSubject, emailBody) {
        currentlySendingEmail();
        const selectedEmails = getSelectedAdminEmails();
        const { error } = await AdminAPI.sendEmailToMasjidAdmins(
            selectedEmails,
            emailSubject,
            emailBody
        );
        currentlyNotSendingEmail();
        if (error) {
            switch (error) {
                // There should be no client error at this point.
                case Constants.SERVER_ERROR:
                    dispatch(showServerErrorAlert());
                    break;
                default:
                    dispatch(showNoInternetAlert());
            }
            return;
        }
        dispatch(
            showSuccessAlert(`Successfully sent emails to ${selectedEmails.length} admin(s)!`)
        );
        hideEmailModal();
    }

    function getSelectedAdminEmails() {
        return (
            Object.entries(emailsChecked)
                /* eslint-disable no-unused-vars */
                .filter(([_, checked]) => checked)
                /* eslint-enable */
                .map(([email]) => email)
        );
    }

    function handleAllCheckChange() {
        return function() {
            const checkedValue = !allEmailsChecked;
            // Modifying the all state by toggling.
            setAllEmailsChecked(checkedValue);
            // Modifying the all super state by using override.
            setAllSuperEmailsChecked(checkedValue);
            // Modifying each of the the masjids state by using override.
            masjids.forEach(masjid => handleMasjidCheckChange(masjid, checkedValue)());
        };
    }

    // If override is supplied to this function, it is given to the all super state
    // otherwise the old value is just toggled.
    // Override is used when this function is called from higher up the chain.
    // For example, when the user toggle's the All checkbox.
    function handleAllSuperCheckChange(override) {
        const overrideSupplied = arguments.length > 0;
        return function() {
            const checkedValue = overrideSupplied ? override : !allSuperEmailsChecked;
            // Modifying the all super state by using override.
            setAllSuperEmailsChecked(checkedValue);
            // Iterating each of the Masjids.
            masjids.forEach(masjid =>
                // Iterating each of their admins.
                masjid.Admins.forEach(admin => {
                    if (AdminUtils.isSuperAdmin(admin)) {
                        // Modifying each of the super admin state from new all super emails state.
                        handleAdminCheckChange(admin, checkedValue)();
                    }
                })
            );
        };
    }

    // If override is supplied to this function, it is given to the masjid state
    // otherwise the old value is just toggled.
    // Override is used when this function is called from higher up the chain.
    // For example, when the user toggle's the All checkbox.
    function handleMasjidCheckChange(masjid, override) {
        const { id, Admins } = masjid;
        const overrideSupplied = arguments.length > 1;
        return function() {
            const checkedValue = overrideSupplied ? override : !masjidEmailsChecked[id];
            // Modifying the masjids state by either toggling or using override.
            toggleMasjidEmailChecked(emails => ({
                ...emails,
                [id]: checkedValue
            }));
            // Modifying each of the admin state from masjid's state or override.
            Admins.forEach(admin => handleAdminCheckChange(admin, checkedValue)());
        };
    }

    // If override is supplied to this function, it is given to the admin email state
    // otherwise the old value is just toggled.
    // Override is used when this function is called from higher up the chain.
    // For example, when the user toggle's the Masjid's checkbox.
    function handleAdminCheckChange(admin, override) {
        const { Email } = admin;
        const overrideSupplied = arguments.length > 1;
        return function() {
            const checkedValue = overrideSupplied ? override : !emailsChecked[Email];
            // Modifying the admin state by either toggling or using override.
            toggleEmailChecked(emails => ({
                ...emails,
                [Email]: checkedValue
            }));
        };
    }

    function renderMasjid(masjid) {
        return (
            <div key={masjid.id} className="col-md-3 mb-4">
                <input
                    checked={!!masjidEmailsChecked[masjid.id]}
                    onChange={handleMasjidCheckChange(masjid)}
                    type="checkbox"
                />
                <div
                    className="ml-2 d-inline"
                    onClick={handleMasjidCheckChange(masjid)}
                    role="button"
                >
                    <h6 className="d-inline">{masjid.Name}</h6>
                </div>
                <ul>
                    {masjid.Admins.map(admin => (
                        <div key={admin.Email} className="mb-2">
                            <input
                                checked={!!emailsChecked[admin.Email]}
                                onChange={handleAdminCheckChange(admin)}
                                type="checkbox"
                            />
                            <div
                                className="ml-2 d-inline"
                                onClick={handleAdminCheckChange(admin)}
                                role="button"
                            >
                                {AdminUtils.isSuperAdmin(admin) ? '(S) ' : ' '}
                                {admin.FirstName} {admin.LastName}{' '}
                                <span className="text-muted">({admin.Email})</span>
                            </div>
                        </div>
                    ))}
                </ul>
            </div>
        );
    }

    const selectedEmails = getSelectedAdminEmails();
    return (
        <div className="view">
            <PageHeader
                title="Email Admins"
                description="Send email to all or some Masjid admins on the Musalleen platform."
            />
            <div className="view-content">
                <InfoCard
                    title="Choose Admins"
                    subTitle="Choose Masjid admins you want to send emails to."
                    showFooter={false}
                >
                    <div className="mb-4">
                        <input
                            checked={allEmailsChecked}
                            onChange={handleAllCheckChange()}
                            type="checkbox"
                        />
                        <div
                            onClick={handleAllCheckChange()}
                            className="ml-2 d-inline"
                            role="button"
                        >
                            <h3 className="d-inline">All</h3>
                        </div>
                    </div>
                    <div className="mb-4">
                        <input
                            checked={allSuperEmailsChecked}
                            onChange={handleAllSuperCheckChange()}
                            type="checkbox"
                        />
                        <div
                            onClick={handleAllSuperCheckChange()}
                            className="ml-2 d-inline"
                            role="button"
                        >
                            <h5 className="d-inline">All Super Admins</h5>
                        </div>
                    </div>
                    <Row>{masjids.map(renderMasjid)}</Row>
                    <Row>
                        <div className="col-md-6 text-muted">(S) *Super Admin</div>
                        <div className="col-md-6 button-right">
                            <Button
                                onClick={showEmailModal}
                                loading={isSendingEmail}
                                disabled={!selectedEmails.length || emailModalVisible}
                            >
                                Send Email
                            </Button>
                        </div>
                    </Row>
                </InfoCard>
            </div>
            <EmailModal
                title={`Send email to ${selectedEmails.length} admin(s)?`}
                isOpen={emailModalVisible}
                isSending={isSendingEmail}
                onSend={sendEmailToAdmin}
                onClose={hideEmailModal}
            />
        </div>
    );
}

EmailAdmins.propTypes = {
    masjids: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    masjids: getMasjids(state)
});

export default connect(mapStateToProps)(EmailAdmins);
