import format from 'sugar/number/format';

const currencify = amount => `$${amount}`;

const convertBoolToString = bool => (bool ? 'Yes' : 'No');

const formatAmount = (amount, isCents = true) =>
    currencify(format(amount / (isCents ? 100 : 1), 2));

export { convertBoolToString, formatAmount };
