// Snippet reference - https://redux.js.org/recipes/reducingboilerplate.
const createActionCreator = (type, ...argNames) => (...args) => {
    const action = { type, payload: {} };
    argNames.forEach((arg, index) => {
        action.payload[arg] = args[index];
    });
    return action;
};

export default createActionCreator;
