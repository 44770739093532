import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { Components, store } from 'musalleen-react-theme';
import { AdminAPI, Constants } from '../../api';
import { StringUtils, DashboardUtils } from '../../utils';
import { PageHeader } from '../../shared/components';

const base64 = require('base-64');

const { InfoCard, Button } = Components;
const { showDialog, showNoInternetAlert, showServerErrorAlert, showDangerAlert } = store.actions;

function AddMasjid({ adminToken, dispatch }) {
    const [masjidPlan, setMasjidPlan] = useState({
        HasAppBase: false,
        HasKioskBase: false,
        HasOnlineBase: false,
        HasPOSBase: false,
        HasTvDisplayBase: false,
        HasTvDisplayAdvancedAddOn: false,
        HasWebsiteBase: false,
        HasAppDonationsAddOn: false,
        HasKioskRecurringDonationsAddOn: false,
        HasKioskClassRegistrationAddOn: false,
        HasKioskEventRegistrationAddOn: false,
        HasOnlineDonationsAddOn: false,
        HasOnlineRecurringDonationsAddOn: false,
        HasOnlineClassRegistrationAddOn: false,
        HasOnlineEventRegistrationAddOn: false,
        HasPOSRecurringDonationsAddOn: false,
        HasWebsiteAdditionalPageAddOn: false
    });
    const [masjidPlanPrices, setMasjidPlanPrices] = useState({
        AppBaseAddOnPrice: '',
        AppDonationsAddOnPrice: '',
        KioskBaseAddOnPrice: '',
        KioskRecurringDonationsAddOnPrice: '',
        KioskClassRegistrationAddOnPrice: '',
        KioskEventRegistrationAddOnPrice: '',
        TvDisplayBaseAddOnPrice: '',
        TvDisplayAdvancedAddOnPrice: '',
        DashboardBaseAddOnPrice: '',
        OnlineBaseAddOnPrice: '',
        OnlineDonationsAddOnPrice: '',
        OnlineRecurringDonationsAddOnPrice: '',
        OnlineClassRegistrationAddOnPrice: '',
        OnlineEventRegistrationAddOnPrice: '',
        POSBaseAddOnPrice: '',
        POSRecurringDonationsAddOnPrice: '',
        WebsiteBaseAddOnPrice: '',
        WebsiteAdditionalPagePrice: '',
        TrialPeriodDays: 0
    });

    async function getPlanPrices() {
        const { error, data } = await AdminAPI.getPlanPrices();
        if (error) {
            switch (error) {
                // Ignoring CLIENT_ERROR since we won't get that here.
                case Constants.SERVER_ERROR:
                    dispatch(showServerErrorAlert());
                    break;
                default:
                    // In case of NETWORK_ERROR or CONNECTION_ERROR.
                    dispatch(showNoInternetAlert());
            }
            return;
        }
        setMasjidPlanPrices(data);
    }

    useEffect(() => {
        getPlanPrices();
    }, []);

    function handleMajsidPlanPropertyChange(column) {
        return function () {
            const newPlan = {
                ...masjidPlan,
                [column]: !masjidPlan[column]
            };
            // Turn off the addons if base is switched off.
            if (column === 'HasKioskBase' && !newPlan.HasKioskBase) {
                newPlan.HasKioskRecurringDonationsAddOn = false;
                newPlan.HasKioskClassRegistrationAddOn = false;
                newPlan.HasKioskEventRegistrationAddOn = false;
            } else if (column === 'HasAppBase' && !newPlan.HasAppBase) {
                newPlan.HasAppDonationsAddOn = false;
            } else if (column === 'HasOnlineBase' && !newPlan.HasOnlineBase) {
                newPlan.HasOnlineDonationsAddOn = false;
                newPlan.HasOnlineRecurringDonationsAddOn = false;
                newPlan.HasOnlineClassRegistrationAddOn = false;
                newPlan.HasOnlineEventRegistrationAddOn = false;
            } else if (column === 'HasPOSBase' && !newPlan.HasPOSBase) {
                newPlan.HasPOSRecurringDonationsAddOn = false;
            } else if (column === 'HasWebsiteBase' && !newPlan.HasWebsiteBase) {
                newPlan.HasWebsiteAdditionalPageAddOn = false;
            } else if (column === 'HasTvDisplayBase' && !newPlan.HasTvDisplayBase) {
                newPlan.HasTvDisplayAdvancedAddOn = false;
            }
            // Turn off online reccuring donations if donations is switched off.
            if (column === 'HasOnlineDonationsAddOn' && !newPlan.HasOnlineDonationsAddOn) {
                newPlan.HasOnlineRecurringDonationsAddOn = false;
            }
            setMasjidPlan(newPlan);
        };
    }

    async function proceed() {
        // Don't allow the admin to select just the online base and not the addons.
        if (
            masjidPlan.HasOnlineBase &&
            !(
                masjidPlan.HasOnlineDonationsAddOn ||
                masjidPlan.HasOnlineClassRegistrationAddOn ||
                masjidPlan.HasOnlineEventRegistrationAddOn
            )
        ) {
            dispatch(
                showDangerAlert(
                    'Cannot select the online base plan without the donations or one of the program registration add ons. Please try again.'
                )
            );
            return;
        }
        const message = `Are you sure want to proceed with these selected plans? The masjid will be charged the following amount monthly after ${masjidPlanPrices.TrialPeriodDays
            } day(s).`;
        const [confirm, { amount }] = await dispatch(
            showDialog('Are you sure?', message, 'Proceed', 'Cancel', {
                amount: {
                    label: 'Amount ($)',
                    initialValue: getCurrentPricing().toString(),
                    type: 'number'
                }
            })
        );
        if (!confirm) {
            return;
        }

        const win = window.open(
            DashboardUtils.getRegisterURL(
                base64.encode(
                    JSON.stringify({
                        ...masjidPlan,
                        totalPrice: amount - 0,
                        CustomAmount: amount !== getCurrentPricing().toString() ? amount - 0 : null,
                        TrialPeriodDays: masjidPlanPrices.TrialPeriodDays,
                        OpsAdminToken: adminToken
                    })
                )
            ),
            '_newtab'
        );
        if (win) {
            win.focus();
        }
    }

    function getCurrentPricing() {
        let currentPrice = 0;
        if (masjidPlan.HasAppBase) {
            currentPrice += +masjidPlanPrices.AppBaseAddOnPrice;
        }
        if (masjidPlan.HasAppDonationsAddOn) {
            currentPrice += +masjidPlanPrices.AppDonationsAddOnPrice;
        }
        if (masjidPlan.HasKioskBase) {
            currentPrice += +masjidPlanPrices.KioskBaseAddOnPrice;
        }
        if (masjidPlan.HasKioskRecurringDonationsAddOn) {
            currentPrice += +masjidPlanPrices.KioskRecurringDonationsAddOnPrice;
        }
        if (masjidPlan.HasKioskClassRegistrationAddOn) {
            currentPrice += +masjidPlanPrices.KioskClassRegistrationAddOnPrice;
        }
        if (masjidPlan.HasKioskEventRegistrationAddOn) {
            currentPrice += +masjidPlanPrices.KioskEventRegistrationAddOnPrice;
        }
        if (masjidPlan.HasOnlineBase) {
            currentPrice += +masjidPlanPrices.OnlineBaseAddOnPrice;
        }
        if (masjidPlan.HasOnlineDonationsAddOn) {
            currentPrice += +masjidPlanPrices.OnlineDonationsAddOnPrice;
        }
        if (masjidPlan.HasOnlineRecurringDonationsAddOn) {
            currentPrice += +masjidPlanPrices.OnlineRecurringDonationsAddOnPrice;
        }
        if (masjidPlan.HasOnlineClassRegistrationAddOn) {
            currentPrice += +masjidPlanPrices.OnlineClassRegistrationAddOnPrice;
        }
        if (masjidPlan.HasOnlineEventRegistrationAddOn) {
            currentPrice += +masjidPlanPrices.OnlineEventRegistrationAddOnPrice;
        }
        if (masjidPlan.HasTvDisplayBase) {
            currentPrice += +masjidPlanPrices.TvDisplayBaseAddOnPrice;
        }
        if (masjidPlan.HasTvDisplayAdvancedAddOn) {
            currentPrice += +masjidPlanPrices.TvDisplayAdvancedAddOnPrice;
        }
        if (masjidPlan.HasPOSBase) {
            currentPrice += +masjidPlanPrices.POSBaseAddOnPrice;
        }
        if (masjidPlan.HasPOSRecurringDonationsAddOn) {
            currentPrice += +masjidPlanPrices.POSRecurringDonationsAddOnPrice;
        }
        if (masjidPlan.HasWebsiteBase) {
            currentPrice += +masjidPlanPrices.WebsiteBaseAddOnPrice;
        }
        if (masjidPlan.HasWebsiteAdditionalPageAddOn) {
            currentPrice += +masjidPlanPrices.WebsiteAdditionalPageAddOnPrice;
        }
        return currentPrice;
    }

    return (
        <div className="view">
            <PageHeader title="Add a New Masjid" description="Add a new masjid to Musalleen." />
            <div className="view-content">
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="Choose Plan"
                            subTitle="Choose the plans and the add-sons you want to this Masjid to have."
                            showFooter={false}
                        >
                            <section className="pricing pt-3 pb-5">
                                <div className="row ">
                                    <div className="col-md-3">
                                        <div className="card mb-5 mb-lg-0">
                                            <div className="card-body">
                                                <h5 className="card-title text-muted text-uppercase text-center">
                                                    Website
                                                </h5>
                                                <h6 className="card-price text-center">
                                                    {`${StringUtils.formatAmount(
                                                        masjidPlanPrices.WebsiteBaseAddOnPrice,
                                                        false
                                                    )}`}

                                                    <span className="period">/month</span>
                                                </h6>
                                                <hr />
                                                <div className="d-flex">
                                                    <div className="p-2">
                                                        {' '}
                                                        <strong>Select Base</strong>
                                                    </div>
                                                    <div className="ml-auto p-2">
                                                        {' '}
                                                        <Toggle
                                                            icons={false}
                                                            checked={masjidPlan.HasWebsiteBase}
                                                            onChange={handleMajsidPlanPropertyChange(
                                                                'HasWebsiteBase'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <h6
                                                    className={`m-2 ${!masjidPlan.HasWebsiteBase
                                                            ? 'text-muted'
                                                            : ''
                                                        }`}
                                                >
                                                    Addons
                                                </h6>{' '}
                                                <div className="d-flex">
                                                    <div
                                                        className={`p-2 ${!masjidPlan.HasWebsiteBase
                                                                ? 'text-muted'
                                                                : ''
                                                            }`}
                                                    >
                                                        Seminary Page:
                                                        {` ${StringUtils.formatAmount(
                                                            masjidPlanPrices.WebsiteAdditionalPageAddOnPrice,
                                                            false
                                                        )}`}
                                                    </div>
                                                    <div className="ml-auto p-2">
                                                        {' '}
                                                        <Toggle
                                                            icons={false}
                                                            disabled={!masjidPlan.HasWebsiteBase}
                                                            checked={
                                                                masjidPlan.HasWebsiteAdditionalPageAddOn
                                                            }
                                                            onChange={handleMajsidPlanPropertyChange(
                                                                'HasWebsiteAdditionalPageAddOn'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card mb-5 mb-lg-0">
                                            <div className="card-body">
                                                <h5 className="card-title text-muted text-uppercase text-center">
                                                    App
                                                </h5>
                                                <h6 className="card-price text-center">
                                                    {`${StringUtils.formatAmount(
                                                        masjidPlanPrices.AppBaseAddOnPrice,
                                                        false
                                                    )}`}

                                                    <span className="period">/month</span>
                                                </h6>
                                                <hr />
                                                <div className="d-flex">
                                                    <div className="p-2">
                                                        {' '}
                                                        <strong>Select Base</strong>
                                                    </div>
                                                    <div className="ml-auto p-2">
                                                        {' '}
                                                        <Toggle
                                                            icons={false}
                                                            checked={masjidPlan.HasAppBase}
                                                            onChange={handleMajsidPlanPropertyChange(
                                                                'HasAppBase'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <h6
                                                    className={`m-2 ${!masjidPlan.HasAppBase ? 'text-muted' : ''
                                                        }`}
                                                >
                                                    Addons
                                                </h6>{' '}
                                                <div className="d-flex">
                                                    <div
                                                        className={`p-2 ${!masjidPlan.HasAppBase
                                                                ? 'text-muted'
                                                                : ''
                                                            }`}
                                                    >
                                                        Donations:
                                                        {` ${StringUtils.formatAmount(
                                                            masjidPlanPrices.AppDonationsAddOnPrice,
                                                            false
                                                        )}`}
                                                    </div>
                                                    <div className="ml-auto p-2">
                                                        {' '}
                                                        <Toggle
                                                            icons={false}
                                                            disabled={!masjidPlan.HasAppBase}
                                                            checked={
                                                                masjidPlan.HasAppDonationsAddOn
                                                            }
                                                            onChange={handleMajsidPlanPropertyChange(
                                                                'HasAppDonationsAddOn'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-2">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title text-muted text-uppercase text-center">
                                                    Kiosk
                                                </h5>
                                                <h6 className="card-price text-center">
                                                    {`${StringUtils.formatAmount(
                                                        masjidPlanPrices.KioskBaseAddOnPrice,
                                                        false
                                                    )}`}
                                                    <span className="period">/month</span>
                                                </h6>
                                                <hr />
                                                <div className="d-flex">
                                                    <div className="p-2">
                                                        <strong>Select Base</strong>
                                                    </div>
                                                    <div className="ml-auto p-2">
                                                        {' '}
                                                        <Toggle
                                                            icons={false}
                                                            checked={masjidPlan.HasKioskBase}
                                                            onChange={handleMajsidPlanPropertyChange(
                                                                'HasKioskBase'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <h6
                                                    className={`m-2 ${!masjidPlan.HasKioskBase ? 'text-muted' : ''
                                                        }`}
                                                >
                                                    Addons
                                                </h6>
                                                <div className="d-flex">
                                                    <div
                                                        className={`p-2 ${!masjidPlan.HasKioskBase
                                                                ? 'text-muted'
                                                                : ''
                                                            }`}
                                                    >
                                                        Recurring Donations:
                                                        {` ${StringUtils.formatAmount(
                                                            masjidPlanPrices.KioskRecurringDonationsAddOnPrice,
                                                            false
                                                        )}`}
                                                    </div>
                                                    <div className="ml-auto p-2">
                                                        {' '}
                                                        <Toggle
                                                            icons={false}
                                                            disabled={!masjidPlan.HasKioskBase}
                                                            checked={
                                                                masjidPlan.HasKioskRecurringDonationsAddOn
                                                            }
                                                            onChange={handleMajsidPlanPropertyChange(
                                                                'HasKioskRecurringDonationsAddOn'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div
                                                        className={`p-2 ${!masjidPlan.HasKioskBase
                                                                ? 'text-muted'
                                                                : ''
                                                            }`}
                                                    >
                                                        Class Registration:
                                                        {` ${StringUtils.formatAmount(
                                                            masjidPlanPrices.KioskClassRegistrationAddOnPrice,
                                                            false
                                                        )}`}
                                                    </div>
                                                    <div className="ml-auto p-2">
                                                        {' '}
                                                        <Toggle
                                                            icons={false}
                                                            disabled={!masjidPlan.HasKioskBase}
                                                            checked={
                                                                masjidPlan.HasKioskClassRegistrationAddOn
                                                            }
                                                            onChange={handleMajsidPlanPropertyChange(
                                                                'HasKioskClassRegistrationAddOn'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div
                                                        className={`p-2 ${!masjidPlan.HasKioskBase
                                                                ? 'text-muted'
                                                                : ''
                                                            }`}
                                                    >
                                                        Event Registration:
                                                        {` ${StringUtils.formatAmount(
                                                            masjidPlanPrices.KioskEventRegistrationAddOnPrice,
                                                            false
                                                        )}`}
                                                    </div>
                                                    <div className="ml-auto p-2">
                                                        {' '}
                                                        <Toggle
                                                            icons={false}
                                                            disabled={!masjidPlan.HasKioskBase}
                                                            checked={
                                                                masjidPlan.HasKioskEventRegistrationAddOn
                                                            }
                                                            onChange={handleMajsidPlanPropertyChange(
                                                                'HasKioskEventRegistrationAddOn'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title text-muted text-uppercase text-center">
                                                    Online
                                                </h5>
                                                <h6 className="card-price text-center">
                                                    {`${StringUtils.formatAmount(
                                                        masjidPlanPrices.OnlineBaseAddOnPrice,
                                                        false
                                                    )}`}
                                                    <span className="period">/month</span>
                                                </h6>
                                                <hr />
                                                <div className="d-flex">
                                                    <div className="p-2">
                                                        <strong>Select Base</strong>
                                                    </div>
                                                    <div className="ml-auto p-2">
                                                        {' '}
                                                        <Toggle
                                                            icons={false}
                                                            checked={masjidPlan.HasOnlineBase}
                                                            onChange={handleMajsidPlanPropertyChange(
                                                                'HasOnlineBase'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <h6
                                                    className={`m-2 ${!masjidPlan.HasOnlineBase
                                                            ? 'text-muted'
                                                            : ''
                                                        }`}
                                                >
                                                    Addons
                                                </h6>
                                                <div className="d-flex">
                                                    <div
                                                        className={`p-2 ${!masjidPlan.HasOnlineBase
                                                                ? 'text-muted'
                                                                : ''
                                                            }`}
                                                    >
                                                        Online Donations:
                                                        {` ${StringUtils.formatAmount(
                                                            masjidPlanPrices.OnlineDonationsAddOnPrice,
                                                            false
                                                        )}`}
                                                    </div>
                                                    <div className="ml-auto p-2">
                                                        {' '}
                                                        <Toggle
                                                            icons={false}
                                                            disabled={!masjidPlan.HasOnlineBase}
                                                            checked={
                                                                masjidPlan.HasOnlineDonationsAddOn
                                                            }
                                                            onChange={handleMajsidPlanPropertyChange(
                                                                'HasOnlineDonationsAddOn'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div
                                                        className={`p-2 ${!(
                                                                masjidPlan.HasOnlineBase &&
                                                                masjidPlan.HasOnlineDonationsAddOn
                                                            )
                                                                ? 'text-muted'
                                                                : ''
                                                            }`}
                                                    >
                                                        Recurring Donations:
                                                        {` ${StringUtils.formatAmount(
                                                            masjidPlanPrices.OnlineRecurringDonationsAddOnPrice,
                                                            false
                                                        )}`}
                                                    </div>
                                                    <div className="ml-auto p-2">
                                                        {' '}
                                                        <Toggle
                                                            icons={false}
                                                            disabled={
                                                                !(
                                                                    masjidPlan.HasOnlineBase &&
                                                                    masjidPlan.HasOnlineDonationsAddOn
                                                                )
                                                            }
                                                            checked={
                                                                masjidPlan.HasOnlineRecurringDonationsAddOn
                                                            }
                                                            onChange={handleMajsidPlanPropertyChange(
                                                                'HasOnlineRecurringDonationsAddOn'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div
                                                        className={`p-2 ${!(
                                                                masjidPlan.HasOnlineBase &&
                                                                masjidPlan.HasOnlineClassRegistrationAddOn
                                                            )
                                                                ? 'text-muted'
                                                                : ''
                                                            }`}
                                                    >
                                                        Online Class Registration:
                                                        {` ${StringUtils.formatAmount(
                                                            masjidPlanPrices.OnlineClassRegistrationAddOnPrice,
                                                            false
                                                        )}`}
                                                    </div>
                                                    <div className="ml-auto p-2">
                                                        {' '}
                                                        <Toggle
                                                            icons={false}
                                                            disabled={!masjidPlan.HasOnlineBase}
                                                            checked={
                                                                masjidPlan.HasOnlineClassRegistrationAddOn
                                                            }
                                                            onChange={handleMajsidPlanPropertyChange(
                                                                'HasOnlineClassRegistrationAddOn'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div
                                                        className={`p-2 ${!(
                                                                masjidPlan.HasOnlineBase &&
                                                                masjidPlan.HasOnlineEventRegistrationAddOn
                                                            )
                                                                ? 'text-muted'
                                                                : ''
                                                            }`}
                                                    >
                                                        Online Event Registration:
                                                        {` ${StringUtils.formatAmount(
                                                            masjidPlanPrices.OnlineEventRegistrationAddOnPrice,
                                                            false
                                                        )}`}
                                                    </div>
                                                    <div className="ml-auto p-2">
                                                        {' '}
                                                        <Toggle
                                                            icons={false}
                                                            disabled={!masjidPlan.HasOnlineBase}
                                                            checked={
                                                                masjidPlan.HasOnlineEventRegistrationAddOn
                                                            }
                                                            onChange={handleMajsidPlanPropertyChange(
                                                                'HasOnlineEventRegistrationAddOn'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title text-muted text-uppercase text-center">
                                                    POS
                                                </h5>
                                                <h6 className="card-price text-center">
                                                    {`${StringUtils.formatAmount(
                                                        masjidPlanPrices.POSBaseAddOnPrice,
                                                        false
                                                    )}`}
                                                    <span className="period">/month</span>
                                                </h6>
                                                <hr />
                                                <div className="d-flex">
                                                    <div className="p-2">
                                                        <strong>Select Base</strong>
                                                    </div>
                                                    <div className="ml-auto p-2">
                                                        {' '}
                                                        <Toggle
                                                            icons={false}
                                                            checked={masjidPlan.HasPOSBase}
                                                            onChange={handleMajsidPlanPropertyChange(
                                                                'HasPOSBase'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <h6
                                                    className={`m-2 ${!masjidPlan.HasPOSBase ? 'text-muted' : ''
                                                        }`}
                                                >
                                                    Addons
                                                </h6>
                                                <div className="d-flex">
                                                    <div
                                                        className={`p-2 ${!masjidPlan.HasPOSBase
                                                                ? 'text-muted'
                                                                : ''
                                                            }`}
                                                    >
                                                        Recurring Donations:
                                                        {` ${StringUtils.formatAmount(
                                                            masjidPlanPrices.POSRecurringDonationsAddOnPrice,
                                                            false
                                                        )}`}
                                                    </div>
                                                    <div className="ml-auto p-2">
                                                        {' '}
                                                        <Toggle
                                                            icons={false}
                                                            disabled={!masjidPlan.HasPOSBase}
                                                            checked={
                                                                masjidPlan.HasPOSRecurringDonationsAddOn
                                                            }
                                                            onChange={handleMajsidPlanPropertyChange(
                                                                'HasPOSRecurringDonationsAddOn'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  eslint-disable */}
                                    <div className="col-md-3">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title  text-uppercase text-center">Tv Display</h5>
                                                <h6 className="card-price text-center">
                                                    {`${StringUtils.formatAmount(masjidPlanPrices.TvDisplayAdvancedAddOnPrice, false)}`}
                                                    <span className="period">/month</span>
                                                </h6>
                                                <hr />
                                                <div className="d-flex ">
                                                    <div className="p-2"><strong>Select Base</strong></div>
                                                    <div className="ml-auto p-2">
                                                        {' '}
                                                        <Toggle
                                                            icons={false}
                                                            checked={masjidPlan.HasTvDisplayBase}
                                                            onChange={handleMajsidPlanPropertyChange('HasTvDisplayBase')}
                                                        />

                                                    </div>
                                                </div>
                                                <h6
                                                    className={`m-2 ${!masjidPlan.HasTvDisplayBase ? 'text-muted' : ''
                                                        }`}
                                                >
                                                    Addons
                                                </h6>
                                                <div className="d-flex">
                                                    <div
                                                        className={`p-2 ${!masjidPlan.HasTvDisplayBase
                                                            ? 'text-muted'
                                                            : ''
                                                            }`}
                                                    >
                                                        Advanced:
                                                        {` ${StringUtils.formatAmount(
                                                            masjidPlanPrices.TvDisplayAdvancedAddOnPrice,
                                                            false
                                                        )}`}
                                                    </div>
                                                    <div className="ml-auto p-2">
                                                        {' '}
                                                        <Toggle
                                                            icons={false}
                                                            disabled={!masjidPlan.HasTvDisplayBase}
                                                            checked={
                                                                masjidPlan.HasTvDisplayAdvancedAddOn
                                                            }
                                                            onChange={handleMajsidPlanPropertyChange(
                                                                'HasTvDisplayAdvancedAddOn'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  eslint-enable */}
                                </div>
                            </section>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-end align-items-center">
                                        <div className="p-2">
                                            <h5>
                                                Total:
                                                {` ${StringUtils.formatAmount(
                                                    getCurrentPricing(),
                                                    false
                                                )} / month`}
                                            </h5>
                                        </div>
                                        <div className="p-2">
                                            <Button onClick={proceed}>Proceed</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </InfoCard>
                    </div>
                </Row>
            </div>
        </div>
    );
}

AddMasjid.propTypes = {
    adminToken: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = ({ admin: { token } }) => ({
    adminToken: base64.decode(token).split(':x')[0]
});

export default connect(mapStateToProps)(AddMasjid);
