import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import './index.scss';

function PageHeader({ title, description, headTitle }) {
    return (
        <div className="view-header d-flex align-items-center">
            <Helmet title={`Musalleen | ${headTitle || title}`} />
            <header className="text-white">
                <h1 className="h5 title text-uppercase">{title}</h1>
                <p className="mb-0 subtitle">{description}</p>
            </header>
            <div className="ml-auto d-flex mt-2" />
        </div>
    );
}

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    /**
     * Title for document's <head>. By default it uses the `title` prop.
     */
    headTitle: PropTypes.string
};

PageHeader.defaultProps = {
    headTitle: ''
};

export default PageHeader;
