import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Components, hooks } from 'musalleen-react-theme';

const { FormInput, Button } = Components;
const { useForm } = hooks;

function EmailModal({ title, isOpen, isSending, onClose, onSend }) {
    const [
        values,
        errors,
        handleInputChange,
        /* eslint-disable */ _ /* eslint-enable */,
        validate,
        resetForm
    ] = useForm(
        {
            emailSubject: '',
            emailBody: ''
        },
        ['emailSubject', 'emailBody']
    );

    useEffect(() => {
        // Clearing out the form when modal is hidden.
        if (!isOpen) {
            resetForm();
        }
    }, [isOpen]);

    async function sendEmail() {
        const formHasErrors = validate();
        if (formHasErrors) {
            return;
        }
        onSend(values.emailSubject.trim(), values.emailBody.trim());
    }

    // Don't dismiss the modal on outside click.
    function noop() {}

    return (
        <Modal isOpen={isOpen} toggle={noop} className="default modal-lg">
            <ModalHeader toggle={onClose}>{title}</ModalHeader>
            <ModalBody>
                <Row>
                    <div className="col-md-12">
                        <FormInput
                            value={values.emailSubject}
                            onChangeValue={handleInputChange('emailSubject')}
                            label="Subject of Email"
                            placeholder="Enter email subject"
                            message={errors.emailSubject}
                            state={errors.emailSubject ? 'danger' : 'default'}
                        />
                    </div>
                    <div className="col-md-12">
                        <FormInput
                            value={values.emailBody}
                            onChangeValue={handleInputChange('emailBody')}
                            label="Body of Email"
                            type="textarea"
                            placeholder="Enter email content"
                            message={errors.emailBody}
                            state={errors.emailBody ? 'danger' : 'default'}
                            maxLength={20000}
                        />
                    </div>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button loading={isSending} color="primary" onClick={sendEmail}>
                    Send
                </Button>{' '}
                <Button color="secondary" disabled={isSending} onClick={onClose}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}

EmailModal.propTypes = {
    title: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isSending: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSend: PropTypes.func.isRequired
};

export default EmailModal;
