import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import screenfull from 'screenfull';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button
} from 'reactstrap';

// icons
import IconFullScreen from 'react-icons/lib/md/crop-free';
import IconLogout from 'react-icons/lib/md/power-settings-new';
import IconMenu from 'react-icons/lib/md/menu';
import { logout } from '../../../store/actions/admin';
// style
import './style.scss';

function Header({ dispatch, admin, toggleDrawer }) {
    function fullScreenToggle() {
        screenfull.toggle();
    }

    function handleLogout() {
        dispatch(logout());
        browserHistory.replace('/signin');
    }

    return (
        <header className="site-head d-flex align-items-center justify-content-between">
            <div className="wrap mr-4">
                <IconMenu
                    size="24"
                    color="#fff"
                    onClick={toggleDrawer}
                    style={{ cursor: 'pointer' }}
                />
            </div>
            <div className="masjid-name">
                <strong className="h3 text-uppercase">Musalleen Operations</strong>
            </div>
            <div className="right-elems ml-auto d-flex">
                <div className="wrap hidden-sm-down">
                    <IconFullScreen size="22" color="#fff" onClick={fullScreenToggle} />
                </div>

                <div className="wrap profile">
                    <UncontrolledDropdown>
                        <DropdownToggle tag="div">
                            <img
                                src={require('../../../assets/images/blank-profile-picture.png')}
                                alt="avatar"
                            />
                        </DropdownToggle>
                        <DropdownMenu right style={{ minWidth: '12rem' }}>
                            <DropdownItem header>{admin}</DropdownItem>
                            <DropdownItem divider />
                            <div className="text-right ml-3 mr-3 mt-2">
                                <Button block color="primary" size="sm" onClick={handleLogout}>
                                    <IconLogout size="15" />
                                    &emsp;Logout
                                </Button>
                            </div>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </div>
        </header>
    );
}

Header.propTypes = {
    dispatch: PropTypes.func.isRequired,
    admin: PropTypes.string.isRequired,
    toggleDrawer: PropTypes.func.isRequired
};

const mapStateToProps = ({ admin: { firstName, lastName } }) => ({
    admin: `${firstName} ${lastName}`
});

export default connect(mapStateToProps)(Header);
