import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { Components, store, hooks } from 'musalleen-react-theme';
import { PageHeader, EmailModal } from '../../shared/components';
import { getMasjid } from '../../store/selectors';
import { AdminAPI, Constants } from '../../api';
import { DashboardUtils } from '../../utils';
import { stripeDashboardUrl } from '../../api/client';

const { InfoCard, Table, DescriptionList } = Components;
const { showNoInternetAlert, showServerErrorAlert, showSuccessAlert } = store.actions;
const { useBoolean } = hooks;

function Masjid({ masjid, dispatch }) {
    const {
        Name,
        SubscriptionState,
        Email,
        PhoneNumber,
        WebsiteUrl,
        CreatedAt,
        AptNumber,
        HouseNumber,
        Street,
        City,
        State,
        Zipcode,
        DonationsEnabled,
        HasAppBase,
        HasKioskBase,
        HasOnlineBase,
        HasTvDisplayBase,
        HasPOSBase,
        HasWebsiteBase,
        HasAppDonationsAddOn,
        HasKioskRecurringDonationsAddOn,
        HasKioskClassRegistrationAddOn,
        HasKioskEventRegistrationAddOn,
        HasOnlineDonationsAddOn,
        HasOnlineRecurringDonationsAddOn,
        HasPOSRecurringDonationsAddOn,
        HasWebsiteAdditionalPageAddOn,
        Admins,
        StripeCustomerID
    } = masjid;

    const [emailModalVisible, showEmailModal, hideEmailModal] = useBoolean(false);
    const [isSendingEmail, currentlySendingEmail, currentlyNotSendingEmail] = useBoolean(false);
    const [adminSelectedForEmail, setAdminSelectedForEmail] = useState('');

    async function logInToDashboard(admin) {
        const { error, data } = await AdminAPI.getMasjidAdminToken(admin.Email);
        if (error) {
            switch (error) {
                // There should be no client error at this point.
                case Constants.SERVER_ERROR:
                    dispatch(showServerErrorAlert());
                    break;
                default:
                    dispatch(showNoInternetAlert());
            }
            return;
        }
        const { token, duration } = data;
        const win = window.open(
            DashboardUtils.getSignInViaOpsURL(token, duration, admin.Email),
            '_newtab'
        );
        if (win) {
            win.focus();
        }
    }

    function handleSendEmailClick(admin) {
        setAdminSelectedForEmail(admin.Email);
        showEmailModal();
    }

    async function sendEmailToAdmin(emailSubject, emailBody) {
        currentlySendingEmail();
        const { error } = await AdminAPI.sendEmailToMasjidAdmin(
            adminSelectedForEmail,
            emailSubject,
            emailBody
        );
        currentlyNotSendingEmail();
        if (error) {
            switch (error) {
                // There should be no client error at this point.
                case Constants.SERVER_ERROR:
                    dispatch(showServerErrorAlert());
                    break;
                default:
                    dispatch(showNoInternetAlert());
            }
            return;
        }
        dispatch(showSuccessAlert(`Successfully sent an email to ${adminSelectedForEmail}.`));
        hideEmailModal();
    }

    return (
        <div className="view">
            <PageHeader
                title={`${Name} (${SubscriptionState})`}
                description="Everything about this masjid."
                headTitle={Name}
            />
            <div className="view-content">
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="Masjid Details"
                            subTitle="More details about this Masjid."
                            showFooter={false}
                        >
                            <DescriptionList
                                list={[
                                    { label: 'Name', value: Name },
                                    { label: 'Subscription State', value: SubscriptionState },
                                    { label: 'Website', value: WebsiteUrl },
                                    { label: 'Email', value: Email },
                                    { label: 'Phone Number', value: PhoneNumber },
                                    { label: 'Joined At', value: CreatedAt },
                                    { label: 'Apt Number', value: AptNumber },
                                    { label: 'House Number', value: HouseNumber },
                                    { label: 'Street', value: Street },
                                    { label: 'City', value: City },
                                    { label: 'State', value: State },
                                    { label: 'Zipcode', value: Zipcode },
                                    { label: 'Payments Enabled', value: DonationsEnabled },
                                    { label: 'Has App Base', value: HasAppBase },
                                    { label: 'Has Kiosk Base', value: HasKioskBase },
                                    { label: 'Has Online Base', value: HasOnlineBase },
                                    { label: 'Has Website Base', value: HasWebsiteBase },
                                    { label: 'Has Tv Base', value: HasTvDisplayBase },
                                    { label: 'Has POS Base', value: HasPOSBase },
                                    {
                                        label: 'Has App Donations',
                                        value: HasAppDonationsAddOn
                                    },
                                    {
                                        label: 'Has Kiosk Recurring Donations',
                                        value: HasKioskRecurringDonationsAddOn
                                    },
                                    {
                                        label: 'Has Kiosk Class Registration',
                                        value: HasKioskClassRegistrationAddOn
                                    },
                                    {
                                        label: 'Has Kiosk Event Registration',
                                        value: HasKioskEventRegistrationAddOn
                                    },
                                    {
                                        label: 'Has Online Donations',
                                        value: HasOnlineDonationsAddOn
                                    },
                                    {
                                        label: 'Has Online Recurring Donations',
                                        value: HasOnlineRecurringDonationsAddOn
                                    },
                                    {
                                        label: 'Has POS Recurring Donations',
                                        value: HasPOSRecurringDonationsAddOn
                                    },
                                    {
                                        label: 'Has Website Additional Page Add On',
                                        value: HasWebsiteAdditionalPageAddOn
                                    }
                                ]}
                            />
                            <a
                                href={`${stripeDashboardUrl}/${StripeCustomerID}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Go to Customer Stripe Dashboard
                            </a>
                        </InfoCard>
                    </div>
                </Row>
                <Row className="mt-4">
                    <div className="col-md-12">
                        <InfoCard
                            title="Masjid Admins"
                            subTitle="Admins of this Masjid."
                            showFooter={false}
                        >
                            <Table
                                title=""
                                columns={[
                                    { label: 'First Name', key: 'FirstName' },
                                    { label: 'Last Name', key: 'LastName' },
                                    { label: 'Email', key: 'Email' },
                                    { label: 'Role', key: 'Role' }
                                ]}
                                enableFilters={false}
                                enableExports={false}
                                data={Admins}
                                showButtonOne
                                buttonOneLabel="Send Email"
                                onButtonOneClick={handleSendEmailClick}
                                showButtonTwo
                                buttonTwoLabel="Log In to Masjid Dashboard via this Admin"
                                onButtonTwoClick={logInToDashboard}
                                rowClickable={false}
                            />
                        </InfoCard>
                    </div>
                </Row>
            </div>
            <EmailModal
                title={`Send Email to ${adminSelectedForEmail}`}
                isOpen={emailModalVisible}
                isSending={isSendingEmail}
                onClose={hideEmailModal}
                onSend={sendEmailToAdmin}
            />
        </div>
    );
}

Masjid.propTypes = {
    masjid: PropTypes.shape({}).isRequired,
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => ({
    masjid: getMasjid(state, props)
});

export default connect(mapStateToProps)(Masjid);
