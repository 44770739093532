import { createSelector } from 'reselect';
import { DateUtils, StringUtils } from '../../utils';

const selectMasjids = ({ masjids: { masjids } }) => masjids;
// const selectStateFromLocation = (_, { location }) => (location ? location.state : null);
const selectStateFromParams = (_, { routeParams }) => (routeParams ? +routeParams.id : null);

export const getMasjids = createSelector(
    selectMasjids,
    masjids =>
        masjids.map(
            ({
                ID,
                Name,
                City,
                SubscriptionState,
                PhoneNumber,
                Email,
                Admins,
                PlanPurchaseAmount,
                DonationsEnabled,
                StripeCustomerID,
                StripeAdminEmail
            }) => ({
                id: ID,
                Name,
                City,
                SubscriptionState,
                PhoneNumber: PhoneNumber || '-',
                Email: Email || '-',
                Admins: Admins.map(admin => ({
                    id: admin.Email,
                    FirstName: admin.FirstName,
                    LastName: admin.LastName,
                    Email: admin.Email,
                    Role: admin.Role,
                    IsDefault: admin.isDefault
                })),
                StripeAdminEmail,
                PlanPurchaseAmount,
                DonationsEnabled,
                StripeCustomerID
            })
        )
);

export const getMasjid = createSelector(
    [selectMasjids, selectStateFromParams],
    (masjids, masjidID) => {
        const {
            Name,
            SubscriptionState,
            WebsiteUrl,
            Email,
            PhoneNumber,
            CreatedAt,
            AptNumber,
            HouseNumber,
            Street,
            City,
            State,
            Zipcode,
            DonationsEnabled,
            HasAppBase,
            HasKioskBase,
            HasOnlineBase,
            HasTvDisplayBase,
            HasPOSBase,
            HasWebsiteBase,
            HasAppDonationsAddOn,
            HasKioskRecurringDonationsAddOn,
            HasKioskClassRegistrationAddOn,
            HasKioskEventRegistrationAddOn,
            HasOnlineDonationsAddOn,
            HasOnlineRecurringDonationsAddOn,
            HasPOSRecurringDonationsAddOn,
            HasWebsiteAdditionalPageAddOn,
            Admins,
            StripeCustomerID
        } = masjids.find(({ ID }) => ID === masjidID);
        return {
            Name,
            StripeCustomerID,
            SubscriptionState,
            WebsiteUrl: WebsiteUrl || '-',
            Email: Email || '-',
            PhoneNumber: PhoneNumber || '-',
            CreatedAt: DateUtils.format(CreatedAt, 'MM/DD/YYYY hh:mm A'),
            AptNumber: AptNumber || '-',
            HouseNumber: HouseNumber || '-',
            Street: Street || '-',
            City: City || '-',
            State: State || '-',
            Zipcode: Zipcode ? `${Zipcode}` : '-',
            DonationsEnabled: StringUtils.convertBoolToString(DonationsEnabled),
            HasAppBase: StringUtils.convertBoolToString(HasAppBase),
            HasKioskBase: StringUtils.convertBoolToString(HasKioskBase),
            HasOnlineBase: StringUtils.convertBoolToString(HasOnlineBase),
            HasTvDisplayBase: StringUtils.convertBoolToString(HasTvDisplayBase),
            HasPOSBase: StringUtils.convertBoolToString(HasPOSBase),
            HasWebsiteBase: StringUtils.convertBoolToString(HasWebsiteBase),
            HasAppDonationsAddOn: StringUtils.convertBoolToString(HasAppDonationsAddOn),
            HasKioskRecurringDonationsAddOn: StringUtils.convertBoolToString(
                HasKioskRecurringDonationsAddOn
            ),
            HasKioskClassRegistrationAddOn: StringUtils.convertBoolToString(
                HasKioskClassRegistrationAddOn
            ),
            HasKioskEventRegistrationAddOn: StringUtils.convertBoolToString(
                HasKioskEventRegistrationAddOn
            ),
            HasOnlineDonationsAddOn: StringUtils.convertBoolToString(HasOnlineDonationsAddOn),
            HasOnlineRecurringDonationsAddOn: StringUtils.convertBoolToString(
                HasOnlineRecurringDonationsAddOn
            ),
            HasPOSRecurringDonationsAddOn: StringUtils.convertBoolToString(
                HasPOSRecurringDonationsAddOn
            ),
            HasWebsiteAdditionalPageAddOn: StringUtils.convertBoolToString(
                HasWebsiteAdditionalPageAddOn
            ),
            Admins: Admins.map(admin => ({
                id: admin.Email,
                FirstName: admin.FirstName,
                LastName: admin.LastName,
                Email: admin.Email,
                Role: admin.Role
            }))
        };
    }
);
