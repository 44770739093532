import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Components } from 'musalleen-react-theme';
import { PageHeader } from '../../shared/components';
import { getMasjids } from '../../store/selectors';

const { InfoCard, Table } = Components;

function MasjidsList({ masjids }) {
    function viewMasjid({ id }) {
        browserHistory.push({ pathname: `/masjids/${id}`, state: id });
    }

    function onAddMasjid() {
        browserHistory.push('/masjids/add');
    }

    return (
        <div className="view">
            <PageHeader title="Masjids" description="All Masjids with Musalleen." />
            <div className="view-content">
                <InfoCard
                    title="All Masjids"
                    subTitle="Here you can see all the Masjids that are registered with Musalleen."
                    showFooter={false}
                    showButtonInHeader
                    buttonInHeaderLabel="Add New Masjid"
                    onClickButtonInHeader={onAddMasjid}
                >
                    <Table
                        title=""
                        defaultSortColumnKey="Name"
                        filters={[
                            {
                                label: 'Masjid Name',
                                key: 'Name'
                            },
                            {
                                label: 'Subscription State',
                                key: 'SubscriptionState',
                                filterInputType: 'select',
                                filterSelectOptions: [
                                    'All',
                                    'ACTIVE',
                                    'NON_ACTIVE',
                                    'GRACE_PERIOD',
                                    'UNSUBSCRIBED_PAYMENT_DUE',
                                    'UNSUBSCRIBED',
                                    'PENDING_UNSUBSCRIBED'
                                ],
                                filterFunc(subscriptionState, chosenSubscriptionState) {
                                    return (
                                        chosenSubscriptionState === 'All' ||
                                        chosenSubscriptionState === subscriptionState
                                    );
                                }
                            },
                            {
                                label: 'Has Donation',
                                key: 'DonationsEnabled',
                                filterInputType: 'select',
                                filterSelectOptions: ['Yes', 'No'],
                                filterFunc(key, value) {
                                    if (value === 'Yes') {
                                        return key === true;
                                    }
                                    if (value === 'No') {
                                        return key === false;
                                    }
                                    // default return masjids which have both donations enabled or disabled
                                    return value === true || value === false;
                                }
                            },
                            { label: 'Stripe Email', key: 'StripeAdminEmail' }
                        ]}
                        columns={[
                            { label: 'Masjid Name', key: 'Name' },
                            { label: 'City', key: 'City' },
                            { label: 'Subscription State', key: 'SubscriptionState' },
                            { label: 'Stripe Email', key: 'StripeAdminEmail' },
                            {
                                label: 'Monthly Price',
                                key: 'PlanPurchaseAmount'
                            }
                        ]}
                        enableExports={false}
                        data={masjids}
                        onRowClick={viewMasjid}
                        itemsPerPage={30}
                    />
                </InfoCard>
            </div>
        </div>
    );
}

MasjidsList.propTypes = {
    masjids: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const mapStateToProps = state => ({
    masjids: getMasjids(state)
});

export default connect(mapStateToProps)(MasjidsList);
