import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { Components } from 'musalleen-react-theme';
import { PageHeader } from '../../shared/components';

const { InfoCard } = Components;

function Home({
    Donations,
    DonationsFromToday,
    DonationsFromKiosk,
    DonationsFromOnline,
    DonationsFromApp,
    DonationsFromRecurring,
    DonationsFromPOS,
    RecurringDonations,
    ActiveRecurringDonations,
    ActiveRecurringDonationsAmount,
    Masjids,
    ActiveMasjids,
    Admins,
    Kiosks,
    KiosksOnLatestVersion,
    POSDevices,
    POSOnLatestVersion,
    Classes,
    Events,
    ClassRegistrations,
    EventRegistrations,
    PushNotifications,
    Announcements,
    DonationRequests,
    Groups,
    Members,
    AllActiveMasjidsMonthlyPlanPrice
}) {
    return (
        <div className="view">
            <PageHeader
                title="Dashboard"
                description="Summary of Musalleen so far."
                headTitle="Ops"
            />
            <div className="view-content view-dashbord">
                <Row className="mb-4">
                    <div className="col-md-3">
                        <InfoCard
                            title="Donations"
                            subTitle={Donations}
                            footerContent="Donations Made So Far"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="Today's Donations"
                            subTitle={DonationsFromToday}
                            footerContent="Donations Made Today Itself"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="Kiosk Donations"
                            subTitle={DonationsFromKiosk}
                            footerContent="Donations Made from Kiosk"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="App Donations"
                            subTitle={DonationsFromApp}
                            footerContent="Donations Made from App"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="Online Donations"
                            subTitle={DonationsFromOnline}
                            footerContent="Donations Made from Portal"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="POS Donations"
                            subTitle={DonationsFromPOS}
                            footerContent="Donations Made from POS"
                            footerIsLink={false}
                        />
                    </div>
                </Row>
                <div className="h5 mb-4">Recurring Donations</div>
                <Row className="mb-4">
                    <div className="col-md-3">
                        <InfoCard
                            title="Recurring Monthly (Active)"
                            subTitle={ActiveRecurringDonationsAmount}
                            footerContent="Amount Received Each Month"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="Recurring Donations Received"
                            subTitle={DonationsFromRecurring}
                            footerContent="Amount Received So Far"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="Recurring Donations (Active)"
                            subTitle={ActiveRecurringDonations}
                            footerContent="Donations Active Each Month"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="Recurring Donations"
                            subTitle={RecurringDonations}
                            footerContent="Recurring Donations Made"
                            footerIsLink={false}
                        />
                    </div>
                </Row>
                <div className="h5 mb-4">Masjids & Admins</div>
                <Row className="mb-4">
                    <div className="col-md-3">
                        <InfoCard
                            title="Masjids (Active)"
                            subTitle={ActiveMasjids}
                            footerContent="Active Masjids Right Now"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="Masjids Onboarded"
                            subTitle={Masjids}
                            footerContent="All Masjids Who Signed Up"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="Admins"
                            subTitle={Admins}
                            footerContent="Admin Count inside Masjids"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="Monthly Price"
                            subTitle={AllActiveMasjidsMonthlyPlanPrice}
                            footerContent="Active Masjids Right Now"
                            footerIsLink={false}
                        />
                    </div>
                </Row>
                <div className="h5 mb-4">Kiosks</div>
                <Row className="mb-4">
                    <div className="col-md-3">
                        <InfoCard
                            title="Kiosks"
                            subTitle={Kiosks}
                            footerContent="Kiosks Running Inside Masjids"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="Kiosks on Latest Version"
                            subTitle={KiosksOnLatestVersion}
                            footerContent="Kiosks Running the Latest Version"
                            footerIsLink={false}
                        />
                    </div>
                </Row>
                <div className="h5 mb-4">POS Devices</div>
                <Row className="mb-4">
                    <div className="col-md-3">
                        <InfoCard
                            title="POS Devices"
                            subTitle={POSDevices}
                            footerContent="POS Devices Active for Masjids"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="POS Devices on Latest Version"
                            subTitle={POSOnLatestVersion}
                            footerContent="Devices Running the Latest Version"
                            footerIsLink={false}
                        />
                    </div>
                </Row>
                <div className="h5 mb-4">Programs</div>
                <Row className="mb-4">
                    <div className="col-md-3">
                        <InfoCard
                            title="Classes"
                            subTitle={Classes}
                            footerContent="Classes Created So Far"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="Events"
                            subTitle={Events}
                            footerContent="Events Created So Far"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="Class Registrations"
                            subTitle={ClassRegistrations}
                            footerContent="Class Registrations Made"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="Event Registrations"
                            subTitle={EventRegistrations}
                            footerContent="Event Registrations Made"
                            footerIsLink={false}
                        />
                    </div>
                </Row>
                <div className="h5 mb-4">Push Notifications</div>
                <Row className="mb-4">
                    <div className="col-md-3">
                        <InfoCard
                            title="Push Notifications"
                            subTitle={PushNotifications}
                            footerContent="Push Notifications Sent So Far"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="Announcements"
                            subTitle={Announcements}
                            footerContent="Announcements Sent So Far"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="Donation Requests"
                            subTitle={DonationRequests}
                            footerContent="Donation Requests Sent So Far"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="Members"
                            subTitle={Members}
                            footerContent="Whom Notifications Can Be Sent To"
                            footerIsLink={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <InfoCard
                            title="Groups"
                            subTitle={Groups}
                            footerContent="Member Groups Inside Masjids"
                            footerIsLink={false}
                        />
                    </div>
                </Row>
            </div>
        </div>
    );
}

Home.propTypes = {
    Donations: PropTypes.string.isRequired,
    DonationsFromToday: PropTypes.string.isRequired,
    DonationsFromKiosk: PropTypes.string.isRequired,
    DonationsFromOnline: PropTypes.string.isRequired,
    DonationsFromApp: PropTypes.string.isRequired,
    DonationsFromPOS: PropTypes.string.isRequired,
    DonationsFromRecurring: PropTypes.string.isRequired,
    RecurringDonations: PropTypes.number.isRequired,
    ActiveRecurringDonations: PropTypes.number.isRequired,
    ActiveRecurringDonationsAmount: PropTypes.string.isRequired,
    Masjids: PropTypes.number.isRequired,
    ActiveMasjids: PropTypes.number.isRequired,
    Admins: PropTypes.number.isRequired,
    Kiosks: PropTypes.number.isRequired,
    KiosksOnLatestVersion: PropTypes.number.isRequired,
    POSDevices: PropTypes.number.isRequired,
    POSOnLatestVersion: PropTypes.number.isRequired,
    Classes: PropTypes.number.isRequired,
    Events: PropTypes.number.isRequired,
    ClassRegistrations: PropTypes.number.isRequired,
    EventRegistrations: PropTypes.number.isRequired,
    PushNotifications: PropTypes.number.isRequired,
    Announcements: PropTypes.number.isRequired,
    DonationRequests: PropTypes.number.isRequired,
    Groups: PropTypes.number.isRequired,
    Members: PropTypes.number.isRequired,
    AllActiveMasjidsMonthlyPlanPrice: PropTypes.string.isRequired
};

const mapStateToProps = ({ analytics }) => ({
    ...analytics
});

export default connect(mapStateToProps)(Home);
