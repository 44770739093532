import { produce } from 'immer';
import { SET_ANALYTICS, DELETE_ANALYTICS } from '../types';

const INITIAL_STATE = {
    Donations: '$0.0',
    DonationsFromToday: '$0.0',
    DonationsFromKiosk: '$0.0',
    DonationsFromOnline: '$0.0',
    DonationsFromApp: '$0.0',
    DonationsFromPOS: '$0.0',
    DonationsFromRecurring: '$0.0',
    RecurringDonations: 0,
    ActiveRecurringDonations: 0,
    ActiveRecurringDonationsAmount: '$0.0',
    Masjids: 0,
    ActiveMasjids: 0,
    Admins: 0,
    Kiosks: 0,
    KiosksOnLatestVersion: 0,
    POSDevices: 0,
    POSOnLatestVersion: 0,
    Classes: 0,
    Events: 0,
    ClassRegistrations: 0,
    EventRegistrations: 0,
    PushNotifications: 0,
    Announcements: 0,
    DonationRequests: 0,
    Groups: 0,
    Members: 0
};

/* eslint-disable no-param-reassign */
const analytics = produce((draft, { type, payload }) => {
    switch (type) {
        case SET_ANALYTICS: {
            return { ...payload.analytics };
        }
        case DELETE_ANALYTICS: {
            return { ...INITIAL_STATE };
        }
        default:
    }
    return draft;
}, INITIAL_STATE);
/* eslint-enable */

export default analytics;
