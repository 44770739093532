import React, { Fragment } from 'react';
import { Router, Route, IndexRoute, Redirect, browserHistory } from 'react-router';
import { AnalyticsUtils } from './utils';
import App from './App';
import { Page404 } from './screens/404';
import { Home } from './screens/home';
import { SignIn } from './screens/signin';
import { EmailAdmins } from './screens/email';
import { MasjidsList, Masjid, AddMasjid } from './screens/masjids';

const routes = (
    <Fragment>
        <Route path="/" component={App}>
            <IndexRoute component={Home} />
            <Route path="masjids/list" component={MasjidsList} />
            <Route path="masjids/add" component={AddMasjid} />
            <Route path="masjids/:id" component={Masjid} />
            <Route path="email" component={EmailAdmins} />
        </Route>

        {/* Auxillary routes outside App. */}
        <Route path="signin" component={SignIn} />
        <Route path="404" component={Page404} />
        <Redirect from="*" to="404" />
    </Fragment>
);

AnalyticsUtils.listenForPageChanges(browserHistory);

export default () => <Router history={browserHistory} routes={routes} />;
