import { store } from 'musalleen-react-theme';
import createActionCreator from './create';
import { SET_MASJIDS, REMOVE_MASJIDS, REFRESHING_MASJIDS } from '../types';
import { AdminAPI, Constants } from '../../api';
import { setAdminDetails } from './admin';
import { setBugsnagUser } from '../../provider/ErrorBoundary';
import { setAnalytics } from './analytics';

const { showNoInternetAlert, showServerErrorAlert } = store.actions;

export const setMasjids = createActionCreator(SET_MASJIDS, 'masjids');
export const removeMasjids = createActionCreator(REMOVE_MASJIDS);
export const setRefreshingMasjids = createActionCreator(REFRESHING_MASJIDS, 'refreshing');

export const refreshMasjids = () => async (dispatch, getState) => {
    const {
        masjids: { updatedAt, interval },
        admin: { email }
    } = getState();
    if (new Date().getTime() <= updatedAt + interval) {
        // This case indicates the data is not stale enough
        // and it has been fetched recently.
        // We don't need to do anything now.
        return null;
    }

    // To indicate we're currently refreshing data.
    dispatch(setRefreshingMasjids(true));

    // Refresh data of masjids.
    const adminResponse = await AdminAPI.get(email);

    if (adminResponse.error) {
        // Ignoring CLIENT_ERROR since we won't get that here.
        if (adminResponse.error === Constants.SERVER_ERROR) {
            dispatch(showServerErrorAlert());
            return adminResponse.error;
        }
        // In case of NETWORK_ERROR or CONNECTION_ERROR.
        dispatch(showNoInternetAlert());
        return adminResponse.error;
    }

    const {
        data: {
            admin: { FirstName, LastName, Email },
            masjids,
            analytics
        }
    } = adminResponse;

    // Save the rest of admin's details.
    dispatch(setAdminDetails(FirstName, LastName, Email));

    // Save the masjids.
    dispatch(setMasjids(masjids));

    // Save the analytics.
    dispatch(setAnalytics(analytics));

    // We're done refreshing data.
    dispatch(setRefreshingMasjids(false));

    // Set the user inside Bugsnag.
    setBugsnagUser(`${FirstName} ${LastName}`, Email, 0, 'Ops');

    return null;
};
