import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Form, FormGroup, Card, CardBody } from 'reactstrap';
import { Components } from 'musalleen-react-theme';
import { login } from '../../store/actions/admin';
import { isLoggedIn } from '../../store/selectors';
import { Constants } from '../../api';

const { Button, Logo, FormInput } = Components;

const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3}/;

function SignIn({ dispatch, isAdminLoggedIn }) {
    const [email, setEmail] = useState('');
    const [invalidEmailMessage, setInvalidEmailMessage] = useState('');

    const [password, setPassword] = useState('');
    const [invalidPasswordMessage, setInvalidPasswordMessage] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isAdminLoggedIn) {
            // Don't show the sign in page if admin is logged in.
            browserHistory.replace('/');
        }
    }, []);

    async function submit() {
        setInvalidEmailMessage('');
        setInvalidPasswordMessage('');

        if (!emailRegex.test(email.trim())) {
            setInvalidEmailMessage('It is not a valid email.');
            return;
        }
        if (!password) {
            setInvalidPasswordMessage('Password cannot be empty.');
            return;
        }

        setLoading(true);

        // Other errors like network errors, server errors etc. are
        // handled inside the action creator itself and don't need to be
        // handled by the UI.
        const error = await dispatch(login(email.trim().toLowerCase(), password));

        setLoading(false);

        if (error) {
            if (error === Constants.CLIENT_ERROR) {
                setInvalidEmailMessage('That is an incorrect combination. Please try again.');
                setInvalidPasswordMessage('That is an incorrect combination. Please try again.');
            }
            return;
        }

        browserHistory.replace('/');
    }

    return (
        <div className="view">
            <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                <Card className="mb-3 form-card">
                    <CardBody>
                        <header className="mb-5">
                            <Link to="/">
                                <Logo align="center" />
                            </Link>
                            <p className="lead text-center">Sign In to Musalleen Operations.</p>
                        </header>
                        <Form>
                            <FormInput
                                label="Enter Email"
                                type="email"
                                name="email"
                                placeholder="someone@xyz.com"
                                state={invalidEmailMessage ? 'danger' : 'default'}
                                message={invalidEmailMessage}
                                value={email}
                                onChangeValue={setEmail}
                            />
                            <FormInput
                                label="Enter Password"
                                type="password"
                                placeholder=""
                                state={invalidPasswordMessage ? 'danger' : 'default'}
                                message={invalidPasswordMessage}
                                value={password}
                                onChangeValue={setPassword}
                                onEnter={submit}
                            />
                            <FormGroup className="text-right">
                                <Button loading={loading} onClick={submit}>
                                    Sign In
                                </Button>
                            </FormGroup>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

SignIn.propTypes = {
    isAdminLoggedIn: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isAdminLoggedIn: isLoggedIn(state)
});

export default connect(mapStateToProps)(SignIn);
