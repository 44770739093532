import client, { returnResponse } from './client';

const base64 = require('base-64');

const { get, post } = client;

const login = async (email, password) =>
    returnResponse(
        await get('/ops-admins/token', null, {
            headers: { Authorization: `Basic ${base64.encode(`${email}:${password}`)}` }
        })
    );

const getAdmin = async email => returnResponse(await get(`/ops-admins/${email}`));

const getMasjidAdminToken = async email =>
    returnResponse(await get(`/ops-admins/admin/${email}/token`));

const sendEmailToMasjidAdmin = async (email, subject, body) =>
    returnResponse(await post(`/ops-admins/admin/${email}/email`, { subject, body }));

const sendEmailToMasjidAdmins = async (emails, subject, body) =>
    returnResponse(await post(`/ops-admins/admins/email`, { emails, subject, body }));

const getPlanPrices = async () => returnResponse(await get('/addons/prices'));

export {
    login,
    getAdmin as get,
    getMasjidAdminToken,
    sendEmailToMasjidAdmin,
    sendEmailToMasjidAdmins,
    getPlanPrices
};
